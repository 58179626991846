/* eslint-disable react/display-name */
import React from "react";
import Markdown from "react-markdown";
import { Button } from "@amzn/awsui-components-react";
import { handleDownload } from "../../../utils";

const actionButtons = (course, type, item) => {
  const linkBtn = item.link ? (
    <Button variant="link" icon="external" href={item.link} />
  ) : null;
  const sourceBtn = item.source ? (
    <Button
      variant="link"
      icon="download"
      onClick={event =>
        handleDownload(event, `${course}/${type}/${item.source}`)
      }
    />
  ) : null;
  return (
    <div>
      {linkBtn}
      {sourceBtn}
    </div>
  );
};
export const SORTABLE_COLUMNS = [
  { id: "lastUpdated", field: "lastUpdated" },
  { id: "title", field: "title" }
];
export const COURSE_COLUMN_DEFINITIONS = (course, type) => [
  {
    id: "title",
    header: () => "Title",
    cell: item => item.title,
    minWidth: "175px",
    allowLineWrap: true
  },
  {
    id: "lastUpdated",
    header: () => "Last Updated",
    cell: item => item.lastUpdated,
    minWidth: "95px",
    allowLineWrap: false
  },
  
  {
    id: "description",
    header: () => "Description",
    cell: item => <Markdown>{item.description}</Markdown>,
    minWidth: "300px",
    allowLineWrap: false
  },
  {
    id: "actions",
    header: () => "Actions",
    cell: item => actionButtons(course, type, item),
    minWidth: "95px",
    allowLineWrap: true
  }
];
