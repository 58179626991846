import React from "react";

import { Table, TableSorting } from "@amzn/awsui-components-react";

import { COURSE_COLUMN_DEFINITIONS, SORTABLE_COLUMNS } from "./CourseTableConfig";

const ResourceTable = ({ course, header, type, items }) => {
  return (
    <div className="awsui-util-mb-l">
      <Table
        columnDefinitions={COURSE_COLUMN_DEFINITIONS(course, type)}
        items={items}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>{`No ${header}`}</b>
            </div>
            <p className="awsui-util-mb-s">{`No ${header} to display.`}</p>
          </div>
        }
      >
        <TableSorting
          sortingColumn="date"
          sortingDescending={true}
          sortableColumns={SORTABLE_COLUMNS}
        />
      </Table>
    </div>
  );
};

export default ResourceTable;
