/* eslint-disable react/display-name */
import React from "react";

import { Button } from "@amzn/awsui-components-react";

import { deliveryMethodMap, formatDate } from "../../../utils";

export const COLUMN_DEFINITIONS = history => [
  {
    id: "id",
    header: () => "ID",
    cell: item => item.id,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "date",
    header: () => "Date",
    cell: item => (
      <Button variant="link" onClick={() => history.push(`/events/${item.id}`)}>
        {formatDate(item.date)}
      </Button>
    ),
    minWidth: "150px",
    allowLineWrap: true
  },
  {
    id: "customers",
    header: () => "Customer(s)",
    cell: item => (item.multiCustomer ? "Multiple" : item.customers[0]),
    minWidth: "125px",
    allowLineWrap: true
  },
  {
    id: "topic",
    header: () => "Topic",
    cell: item => item.topic,
    minWidth: "85px",
    allowLineWrap: true
  },
  {
    id: "location",
    header: () => "Location",
    cell: item => item.location,
    minWidth: "100px",
    allowLineWrap: true
  },
  {
    id: "deliveryMethod",
    header: () => "Delivery Method",
    cell: item => deliveryMethodMap(item.deliveryMethod),
    minWidth: "85px",
    allowLineWrap: true
  },
  {
    id: "csat",
    header: () => "CSAT Score",
    cell: item => (item.csat.score && item.csat.score > -1 ? item.csat.score : "-"),
    minWidth: "50px"
  }
];

export const SORTABLE_COLUMNS = [
  { id: "date", field: "date" },
  { id: "customers", field: "customers" },
  { id: "topic", field: "topic" },
  { id: "deliveryMethod", field: "deliveryMethod" },
  { id: "csat", field: "csat" }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Table settings",
    options: [
      { id: "id", label: "ID", editable: false, visible: false },
      { id: "date", label: "Date", editable: true, visible: true },
      { id: "customers", label: "Customer(s)", editable: true, visible: true },
      { id: "location", label: "Location", editable: true, visible: true },
      { id: "topic", label: "Topic", editable: true, visible: true },
      {
        id: "deliveryMethod",
        label: "Delivery Method",
        editable: true,
        visible: true
      },
      { id: "csat", label: "CSAT Score", editable: true, visible: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10" },
  { value: 30, label: "30" },
  { value: 50, label: "50" }
];
